import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/auth/services/o-auth-storage.service';
import { CommonsHttpService } from '@qaroni-core/services/commons/commons-http/commons-http.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { CompanyModule } from '../company.module';
import { Company } from '../types/company';
import { CompanyAddress } from '../types/company-address';
import { CompanyCache } from '../types/company-cache.class';
import { CompanyModules } from '../types/company-modules';
import { CompanyRegisterJson } from '../types/company-register-json';
import { CompanyUpdateJson } from '../types/company-update-json';
import { Scoreboards } from '../types/scoreboards';
import { CompanyHttpService } from './company-http.service';
import { CompanySnackbarsService } from './company-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  protected readonly companiesSubject = new Subject<Company[]>();

  protected readonly companySubject = new Subject<Company>();
  private company: CompanyCache = new CompanyCache();
  // TODO: Think and study when remove method will call

  protected readonly companyScoreboardsSubject = new Subject<Scoreboards>();
  protected readonly messageSubject = new Subject<string>();
  protected readonly companyModulesSubject = new BehaviorSubject<
    CompanyModules[]
  >([]);

  constructor(
    private commonsHttp: CommonsHttpService,
    private companyHttpService: CompanyHttpService,
    private oAuthStorage: OAuthStorageService,
    public snackbars: CompanySnackbarsService
  ) {}

  public getMessage$(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  // ==========================================================================================
  // Get Companies
  // ==========================================================================================

  public getCompanies$(): Observable<Company[]> {
    return this.companiesSubject.asObservable();
  }

  public getCompanies(params?: Params): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.companyHttpService.getCompanies$(params).subscribe({
      next: this.nextGetCompanies,
      error: this.errorGetCompanies,
    });
  }

  private nextGetCompanies = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const companies: Company[] = data.body.result;
      this.companiesSubject.next(companies);
    } else {
      this.companiesSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetCompanies = (error: HttpErrorResponse): void => {
    this.companiesSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureValidationError();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Register Company
  // ==========================================================================================

  public getCompany$(): Observable<Company> {
    return this.companySubject.asObservable();
  }

  public resetCompanyCache() {
    return this.company.empty();
  }

  public registerCompany(companyRegisterJson: CompanyRegisterJson): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.companyHttpService.registerCompany$(companyRegisterJson).subscribe({
      next: this.nextRegisterCompany,
      error: this.errorRegisterCompany,
    });
  }

  private nextRegisterCompany = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus201(data) &&
      data?.body?.result?.length
    ) {
      this.company.set(data.body.result[0]);
      this.companySubject.next(this.company.get());
    } else {
      this.companySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorRegisterCompany = (error: HttpErrorResponse): void => {
    this.companySubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureValidationError();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Get Company
  // ==========================================================================================

  public getCompany(companyID: number, searchCache: boolean = true): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    if (this.company.canReturn(companyID) && searchCache) {
      this.companySubject.next(this.company.get());
    } else if (this.company.canApi(+companyID)) {
      this.company.startToFly();
      this.companyHttpService
        .getCompany$(companyID)
        .pipe(finalize(() => this.company.endFlying()))
        .subscribe({
          next: this.nextGetCompany,
          error: this.errorGetCompany,
        });
    }
  }

  public getCompanyFreshData(companyID: number): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.companyHttpService.getCompany$(companyID).subscribe({
      next: this.nextGetCompany,
      error: this.errorGetCompany,
    });
  }

  private nextGetCompany = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus200(data) &&
      data?.body?.result?.length
    ) {
      this.company.set(data.body.result[0]);
      this.companySubject.next(this.company.get());
    } else {
      this.companySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetCompany = (error: HttpErrorResponse): void => {
    this.companySubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureValidationError();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Update Company Address
  // ==========================================================================================

  public updateCompanyAddress(
    companyID: number,
    address: CompanyAddress
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.companyHttpService
      .updateCompanyAddress$(companyID, address)
      .subscribe({
        next: this.nextUpdateCompanyAddress,
        error: this.errorUpdateCompanyAddress,
      });
  }

  private nextUpdateCompanyAddress = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus200(data) &&
      data?.body?.result?.length
    ) {
      this.company.set(data.body.result[0]);
      this.companySubject.next(this.company.get());
    } else {
      this.companySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorUpdateCompanyAddress = (error: HttpErrorResponse): void => {
    this.companySubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureValidationError();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Update Company
  // ==========================================================================================

  public updateCompany(
    companyID: number,
    companyUpdateJson: CompanyUpdateJson
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.companyHttpService
      .updateCompany$(companyID, companyUpdateJson)
      .subscribe({
        next: this.nextUpdateCompany,
        error: this.errorUpdateCompany,
      });
  }

  private nextUpdateCompany = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus200(data) &&
      data?.body?.result?.length
    ) {
      this.company.set(data.body.result[0]);
      this.companySubject.next(this.company.get());
    } else {
      this.companySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorUpdateCompany = (error: HttpErrorResponse): void => {
    this.companySubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureValidationError();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Remove Company
  // ==========================================================================================

  public removeCompany(companyID: number): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.companyHttpService.removeCompany$(companyID).subscribe({
      next: this.nextRemoveCompany,
      error: this.errorRemoveCompany,
    });
  }

  private nextRemoveCompany = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus204(data)) {
      this.company.empty();
      this.companySubject.next(this.company.get());
      this.messageSubject.next('OK deleted company');
    } else {
      this.companySubject.next(null);
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorRemoveCompany = (error: HttpErrorResponse): void => {
    this.companySubject.next(null);
    this.messageSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureValidationError();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Get Company Scoreboards
  // ==========================================================================================

  public getCompanyScoreboards$(): Observable<Scoreboards> {
    return this.companyScoreboardsSubject.asObservable();
  }

  public getCompanyScoreboards(companyID: number): void {
    if (!this.oAuthStorage.hasOAuth || !Number.isInteger(companyID)) {
      return;
    }

    this.companyHttpService.getCompanyScoreboards$(companyID).subscribe({
      next: this.nextGetCompanyScoreboards,
      error: this.errorGetCompanyScoreboards,
    });
  }

  private nextGetCompanyScoreboards = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const scoreboards: Scoreboards = data.body.result[0];
      this.companyScoreboardsSubject.next(scoreboards);
    } else {
      this.companyScoreboardsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetCompanyScoreboards = (error: HttpErrorResponse): void => {
    this.companyScoreboardsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Get Company Modules
  // ==========================================================================================

  public getCompanyModules$(): Observable<CompanyModule> {
    return this.companyModulesSubject.asObservable().pipe(shareReplay(1));
  }

  public getCompanyModules(companyID: number) {
    if (!this.oAuthStorage.hasOAuth || !Number.isInteger(companyID)) {
      return;
    }

    this.companyHttpService.getCompanyModules$(companyID).subscribe({
      next: this.nextGetCompanyModules,
      error: this.errorGetCompanyModules,
    });
  }

  private nextGetCompanyModules = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const modules = data.body.result;
      this.companyModulesSubject.next(modules);
    } else {
      this.companyModulesSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetCompanyModules = (error: HttpErrorResponse): void => {
    this.companySubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };
}
