import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQWorkardsEnv } from '@qaroni-core/config/apis/api-qworkards/api-qworkards.config';
import { Observable } from 'rxjs';
import { CompanyAddress } from '../types/company-address';
import { CompanyRegisterJson } from '../types/company-register-json';
import { CompanyUpdateJson } from '../types/company-update-json';

@Injectable({
  providedIn: 'root',
})
export class CompanyHttpService {
  constructor(private http: HttpClient) {}

  public getCompanies$(params?: Params): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies`;

    return this.http.get(url, { observe: 'response', params });
  }

  public registerCompany$(
    companyRegisterJson: CompanyRegisterJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies`;

    return this.http.post(url, companyRegisterJson, { observe: 'response' });
  }

  public getCompany$(companyID: number): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}`;

    return this.http.get(url, { observe: 'response' });
  }

  public updateCompany$(
    companyID: number,
    companyUpdateJson: CompanyUpdateJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}`;

    return this.http.patch(url, companyUpdateJson, { observe: 'response' });
  }

  public updateCompanyAddress$(
    companyID: number,
    address: CompanyAddress
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/addresses`;

    return this.http.patch(url, address, { observe: 'response' });
  }

  public removeCompany$(companyID: number): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}`;

    return this.http.delete(url, { observe: 'response' });
  }

  public getCompanyScoreboards$(
    companyID: number
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/dashboards`;

    return this.http.get(url, { observe: 'response' });
  }

  public getCompanyModules$(companyID: number) {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/modules`;

    return this.http.get(url, { observe: 'response' });
  }
}
